.App {
  font-family: sans-serif;
  text-align: center;
  height: 100vh;
  overflow: hidden;
  scroll-snap-align: start;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  position: relative;
  &-content {
    height: 100%;
    scroll-snap-align: start;
    scroll-snap-type: y ;
    scroll-behavior: smooth;
    overflow: scroll;
  }
  & section {
    position: relative;
    // scroll-snap-type: y proximity;
    // scroll-behavior: smooth;  
    // scroll-snap-align: start;
  }
}

.u-heading {
  font-size: 40px;
  width: 100%;
  margin-top: 150px;
  font-family: 'Yatra One', sans-serif;
  letter-spacing: 2px;
}
.u-description {
  line-height: 1.5em;
  font-size: 18px;
  text-align: left;
}

// slide in on scroll styles
// ${isVisible ? " " : ""}  motion-reduce:transition-none motion-reduce:hover:transform-none

.isVisible {
// opacity-100 blur-none translate-x-0
  opacity: 100;
  filter: blur(0);
  transform: translateX(0);

 }

 .isNotVisible {
// opacity-0 blur-lg -translate-x-20
opacity: 0;
filter: blur(16px);
transform: translateX(20px);
}

.isVisible, isNotVisible {
  transition: .5s all;
}