.About {
  padding: 30px 20px 90px;
  min-height: 70vh;
  background: 
    linear-gradient(#1d3356, #2c375e 40%,  #1b2342 ), 
    radial-gradient(#73d588, transparent, transparent),
    linear-gradient(to bottom left, #b0672a78, transparent 80%),  
    linear-gradient(to bottom right, #0b2737, transparent); 
  background-blend-mode: overlay;
  display:flex;
  justify-content: center;
  align-items: center;
      @media screen and (min-width: 1000px) {
      flex-direction: row;
    }
    &-content {
      max-width: 1200px;
      position: relative;
      margin: 0 auto;
      display: flex;
      align-items: center;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      @media screen and (min-width: 1000px) {
        flex-direction: row;
      }
    }
    &-heading {
      width: 100%;
      text-transform: uppercase;
      color: #d65c54;
    }
    &-image {
      padding-bottom: 50px;
      object-fit: cover;
      object-position: left top;
      //height: 60vw;
      width: 40%;
      max-height: 48vw;
      display: block;
      aspect-ratio: 3/1;
      @media screen and (min-width: 1000px) {
        height: 600px;
        width: 37%;
        padding-bottom: 0;
      }
    }
    &-description {
      color: rgb(179, 174, 166);
      padding: 20px 0;
      width: 100%;
      margin-left: 0;
      @media screen and (min-width: 1000px) {
        width: calc(63% - 88px);
        margin-left: 88px;
        padding: 0;

      }  
      &-emphasis {
        font-weight: bold;
        color: rgb(206, 199, 187);
      }
    }    
}

