$itemSize: 500px;

.PortfolioSection {
  width: 100%;
  color: rgba(247, 238, 222, 0.85);
  margin-top: 40px;
  overflow: hidden;

  &-button {
    border: 0;
    display: block;
    width: auto;
    max-width: 700px;
    margin: 0 auto;
    background-color: rgba(95, 78, 112, 0);
    transition: .8s all;
    color: #cc554d;
    width: 60%;
    cursor: pointer;
  }
  &-header {
    padding-bottom: 8px;
    font-size: 24px;
    font-family: 'Yatra One';
    &:after {
      content: '';
      display: block;
      margin: 0 auto;
      border-bottom: solid 1px #cc554d;
      width: 0;
      transition: 1s width ease-in-out;
    }
    &:hover {
      &:after {
        width: 100%;
      }
    } 
    &.isActive {
      &:after {
        width: 100%;
      }
      &:before {
        content: '';
        display: block;
        margin: 0 auto;
      }
    }
  }
  &-headerChevron {
    display: inline-block;
    transition: transform;
    transition-duration: .2s;
    transform-origin: center;
    margin-left: 10px;    
    &.isActive {
      transform: rotate(180deg);      
    }
  }
  &-content {
    position: relative;
    margin: 0 auto;
    width: auto;
    height: 0;
    overflow: hidden;
    transition: 1s height ease-in-out;
    &.isActive {
      height: 540px; //@TODO update to use var
      height: calc(#{$itemSize} + 40px)
    }
    &::webkit-toolbar {
      display: none;

    }
  }

  &-itemsWrap {
    position: absolute;
    overflow: hidden;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    padding-top: 20px;
    padding-bottom: 20px;
    white-space: nowrap;
    width: 100%;
  }
}