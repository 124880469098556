// this section is an outlier
// it is in the hero section for easier ofr
section.Nav {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.Nav {
  &-wrap {
    display: grid;
    grid-template-rows: repeat(4, 1fr);    
    grid-template-columns: repeat(4, 1fr);
    overflow: hidden;

    margin: 0 auto;
    max-width: 650px;
    display: flex;
    flex-direction: row;    
  }
  &-button { 
    border: none;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 24px;
    background: none;
    flex-grow: 1;
    padding: 0;
    cursor: pointer;
    text-decoration: none;

    &--about {
      grid-row: 1 / span 2;
      grid-column: 2 / span 2;
    }
    &--blog {
      grid-row: 2 / span 2;
      grid-column: 1 / span 2;
    }
    &--portfolio {
      grid-row: 2 / span 2;
      grid-column: 3 / span 2;
    }
    &--contact {
      z-index: 1; // needs to be able to be above it's popover bg
      grid-row: 3 / span 2;
      grid-column: 2 / span 2;
    }
  }

}
