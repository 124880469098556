.Portfolio {
  padding-top: 30px;
  background: 
  linear-gradient(#1b2342, #1d3356), 
  linear-gradient(transparent, rgba(216,81,110, .4), transparent, transparent),
  linear-gradient(to top right, #0b2737, transparent),
  linear-gradient(to top left, #b0672a78, transparent 80%);
  background-blend-mode: overlay;
  min-height: 60vh;

  &-header {
    color: #e67e32;
    text-transform: uppercase;
  }
  &-description {
    color: #e2bda2;
    max-width: 700px;
    margin: 50px auto 100px;
    line-height: 1.5em;
    text-align: left;
  }
  &-content {
    position: relative;
  }

  // &-bgSun {
  //   display: none;
  //   position: absolute;
  //   top: 3%;
  //   background-color: rgba(247, 238, 222, 0.85);
  //   background-blend-mode: lighten;
  //   border: 0;
  //   padding: 20px 40px;
  //   border-radius: 8px;
  //   color: #9d3d5c;
  //   font-size: 24px;
  //   height: 200px;
  //   width: 200px;
  //   border-radius: 50%;
  //   transition: .8s all;
  //   &:hover {
  //     background-color: #eeb68d;
  //     color: #0c101c;
  //     box-shadow: 0 0 30px #e67e32;
  //   }
  // }

}