.NavButtonHex {
  min-width: 80px;
  max-width: 100px;

  @media screen and (min-width: 600px) {
    min-width: 100px;
    max-width: 150px;
  }  
  &-shape {
    fill: #6f3d50;
    stroke: rgb(236, 200, 134); 
    stroke-width: 1px;
    &:hover {
      opacity: 0.4;
    }
  }
  &-glow {
    fill: transparent; 
    stroke: rgb(235, 221, 197); 
    stroke-width: 3px;

    stroke-dasharray: 1000;  
    stroke-dashoffset: 1000;
    transition: 0.8s all;

    &:hover{
      stroke-dashoffset: 0;
      -webkit-filter: drop-shadow(0px 0px 7px rgba(235, 221, 197, .5));      
      filter: drop-shadow(0px 0px 7px rgba(235, 221, 197, .5));  
    }
  }
  &-text {
    fill: #e7d8bd; 
    font-weight: 500;
    font-size: 14px;
  }
}
