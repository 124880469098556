.Blog {
  background-image: url("../assets/images/sunset_mountains.svg");
  background-size: cover;
  min-height: 60vh;
  padding-top: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-wrap {
    background: linear-gradient(#1d3356, #2f243f 40%),
    linear-gradient(to bottom left, #b0672a78, transparent 80%),
    linear-gradient(to bottom right, #0b2737, transparent);
    background-blend-mode: overlay;
    }
  &-description {
    max-width: 620px;
    color: #b3aea6;
  }
  &-linkWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 350px;
    width: 350px;

  }
  &-link {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0;
    padding: 30px 60px;
    border-radius: 4px;
    font-size: 24px;
    width: 200px;
    transition: .8s all;
    margin: 55px 10px;
    background-color: rgba(247, 238, 222, 0.9);
    // background-blend-mode: lighten;
    color: #9d3d5c;
    &:hover {
      background-color: #eeb68d;
      color: #0c101c;
      box-shadow: 0 0 3px #e67e32;
      // padding: 120px 60px;
      // border-radius: 50%;
    }
}
  &-buttonEmph {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 28px;
    font-family: 'Yatra One', sans-serif;
  }
}