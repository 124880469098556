.Hero {
  margin: 0;
  position: relative;
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  // targetting the animation wrapper
  & div {
    width: 100%;
  }
  &-wrapper {
    background: 
      linear-gradient(#643b6a, #1d3356, #1d3356), 
      linear-gradient(to top left, #b0672a78, transparent),  
      linear-gradient(to top right, #0b2737, transparent); 
    background-blend-mode: overlay;
    padding: 10px;
    height: 95vh;
    overflow: hidden;

    @media screen and (min-width: 640px) {
      padding: 50px 50px 0;
    }
  }

  &-text {
    width: 100%;
    max-width: 1400px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 100px;
    font-family: 'Yatra One', sans-serif;
  }
  &-subHeader {
    color: #e2b29e;
    font-weight: lighter;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 7px;
    white-space: nowrap;
    @media screen and (min-width: 780px) {
      font-size: 18px;
    }
    @media screen and (min-width: 1320px) {
      font-size: 24px;
    }
  }
}


// image background is set in js based on random number
// each has a corresponding bg color below
.img-layer {
  fill: url(#p-img);
}

// background colors correspond with hero variations,
// and are randomized and set in js
.hc2 {
  background-color: #6b787e;
}
.hc3 {
  background-color: #0c222d;
}
.hc4 {
  background-color: #0c2926;
}
.hc5 {
 background-color: #0a1630; 
}
.hc6 {
  background-color: #9f6e86f7;
}
.hc7 {
  background-color: #6d4979;
}
.hc8 {
  background-color: #666270;
}
